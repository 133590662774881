<template>
  <div
    v-epi-edit="'MainContent'" 
    class="pt-32 sm:pt-56 full-width-content-area overflow-x-hidden">
    <EpiserverContentArea :model="model.mainContent" :full-width="true" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { useCartStore } from '~/store/cart';
import { CartType} from '~/api-types';
import { useUiStore } from '~/store/ui';
import useDisableScroll from '~/composables/useDisableScroll';
import { useGlobalContentStore } from '~/store/globalContent';

const globalContentStore = useGlobalContentStore();
const { disableScroll } = useDisableScroll();
const uiStore = useUiStore();
const cartStore = useCartStore();

defineProps(['model']);

onMounted(async() => {
  nextTick(async() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    let orderNumber = params.get('orderNo') as string;

    if (process.client && orderNumber) {
      try {
        await globalContentStore.fetchAntiForgeryToken();

        orderNumber = atob(orderNumber);
        var success = await cartStore.repurchaseOrderFromOrderNo(orderNumber, CartType.Standard);

        if (success) {
          uiStore.setShowCartMenu(true);
          disableScroll();

          window.dataLayer?.push({
            event: 'Cart',
            category: 'cart',
            action: 'view',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  });
});
</script>
